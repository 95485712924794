import React from 'react'
import styled from 'styled-components'

import { GridList, Logo } from '@te-digi/styleguide'
import tokens from '@te-digi/styleguide-tokens'

import tyomarkkinatoriSvg from '@te-digi/styleguide/assets/tyomarkkinatori.svg'
import tyomarkkinatoriEnSvg from '@te-digi/styleguide/assets/tyomarkkinatori-en.svg'
import tyomarkkinatoriSvSvg from '@te-digi/styleguide/assets/tyomarkkinatori-sv.svg'
import tyomarkkinatoriPrimarySvg from '@te-digi/styleguide/assets/tyomarkkinatori-primary.svg'
import tyomarkkinatoriEnPrimarySvg from '@te-digi/styleguide/assets/tyomarkkinatori-en-primary.svg'
import tyomarkkinatoriSvPrimarySvg from '@te-digi/styleguide/assets/tyomarkkinatori-sv-primary.svg'
import tyomarkkinatoriAsiantuntijaSvg from '@te-digi/styleguide/assets/tyomarkkinatori-asiantuntija.svg'

import atmtWhiteFiSvg from '@te-digi/styleguide/assets/ATMT_logo_white_fi.svg'
import atmtWhiteSvSvg from '@te-digi/styleguide/assets/ATMT_logo_white_sv.svg'
import atmtPrimaryFiSvg from '@te-digi/styleguide/assets/ATMT_logo_primary_fi.svg'
import atmtPrimarySvSvg from '@te-digi/styleguide/assets/ATMT_logo_primary_sv.svg'

import ohjeetJaTukiTmtFiSvg from '@te-digi/styleguide/assets/ohjeet-ja-tuki-tmt-fi.svg'
import ohjeetJaTukiTmtSvSvg from '@te-digi/styleguide/assets/ohjeet-ja-tuki-tmt-sv.svg'
import ohjeetJaTukiTmtEnSvg from '@te-digi/styleguide/assets/ohjeet-ja-tuki-tmt-en.svg'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

const StyledWrapper = styled.div<{ dark?: boolean }>`
  background-color: ${props => props.dark && tokens.color.dark};
  border-radius: ${tokens.radius.md};
  padding: ${tokens.space.md};
  width: 100%;

  img {
    display: block;
    height: 4rem;
    margin: 0 auto;
    max-width: 100%;
  }
`

const StyledWrapper2 = styled.div`
  padding: ${tokens.space.md};
  width: 100%;

  img {
    display: block;
    height: 4rem;
    margin: 0 auto;
    max-width: 100%;
  }
`
// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Logo"
    components={[{ component: Logo }]}
  >
    <Section>
      <Playground
        isDark
        WrapperComponent={StyledWrapper2}
      >
        <Logo
          alt="Työmarkkinatori"
          src={tyomarkkinatoriSvg}
        />
      </Playground>
      <Playground
        format="html"
        isDark
        WrapperComponent={StyledWrapper2}
      >
        <img
          alt="Työmarkkinatori"
          src={tyomarkkinatoriSvg}
        />
      </Playground>
    </Section>
    <Section title="Työmarkkinatori, valkoinen tummalle taustalle">
      <GridList lg={3}>
        <StyledWrapper dark>
          <Logo
            alt="Työmarkkinatori"
            src={tyomarkkinatoriSvg}
          />
        </StyledWrapper>
        <StyledWrapper dark>
          <Logo
            alt="Job Market"
            src={tyomarkkinatoriEnSvg}
          />
        </StyledWrapper>
        <StyledWrapper dark>
          <Logo
            alt="Jobbmarknad"
            src={tyomarkkinatoriSvSvg}
          />
        </StyledWrapper>
      </GridList>
    </Section>
    <Section title="Työmarkkinatori, tumma valkoiselle taustalle">
      <GridList lg={3}>
        <StyledWrapper>
          <Logo
            alt="Työmarkkinatori"
            src={tyomarkkinatoriPrimarySvg}
          />
        </StyledWrapper>
        <StyledWrapper>
          <Logo
            alt="Job Market"
            src={tyomarkkinatoriEnPrimarySvg}
          />
        </StyledWrapper>
        <StyledWrapper>
          <Logo
            alt="Jobbmarknad"
            src={tyomarkkinatoriSvPrimarySvg}
          />
        </StyledWrapper>
      </GridList>
    </Section>
    <Section title="Asiantuntijan Työmarkkinatori, valkoinen tummalle taustalle">
      <GridList lg={3}>
        <StyledWrapper dark>
          <Logo
            alt="Asiantuntijan Työmarkkinatori"
            src={atmtWhiteFiSvg}
          />
        </StyledWrapper>

        <StyledWrapper dark>
          <Logo
            alt="Jobbmarknaden för Sakkunniga"
            src={atmtWhiteSvSvg}
          />
        </StyledWrapper>
        <StyledWrapper dark>
          <Logo
            alt="Asiantuntijan Työmarkkinatori"
            src={tyomarkkinatoriAsiantuntijaSvg}
          />
        </StyledWrapper>
      </GridList>
    </Section>
    <Section title="Asiantuntijan Työmarkkinatori, valkoiselle taustalle">
      <GridList lg={3}>
        <StyledWrapper>
          <Logo
            alt="Asiantuntijan Työmarkkinatori"
            src={atmtPrimaryFiSvg}
          />
        </StyledWrapper>
        <StyledWrapper>
          <Logo
            alt="Jobbmarknaden för Sakkunniga"
            src={atmtPrimarySvSvg}
          />
        </StyledWrapper>
      </GridList>
    </Section>
    <Section title="Työmarkkinatori Ohjeet ja tuki">
      <GridList lg={3}>
        <StyledWrapper dark>
          <Logo
            alt="Ohjeet ja tuki"
            src={ohjeetJaTukiTmtFiSvg}
          />
        </StyledWrapper>
        <StyledWrapper dark>
          <Logo
            alt="Anvisningar och stöd"
            src={ohjeetJaTukiTmtSvSvg}
          />
        </StyledWrapper>
        <StyledWrapper dark>
          <Logo
            alt="Instructions and support"
            src={ohjeetJaTukiTmtEnSvg}
          />
        </StyledWrapper>
      </GridList>
    </Section>
  </Content>
)

export default Page
